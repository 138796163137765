import { Lozenge, ProfileBlock } from "@k8slens/lds";
import { type Icon } from "@k8slens/lds-icons/lib/es/Icon/Icon.d";
import { ProfileBlockProps } from "@k8slens/lds/lib/es/ProfileBlock/ProfileBlock";
import { useCallback, useMemo } from "react";

import styles from "src/components/TableCells/AvatarTableCell.module.css";

interface AvatarTableCellProps {
  /** render as `@name` */
  name: string;
  /** show role after username if provided */
  role?: string;
  /** hidden if undefined, renders under the `@name` line */
  subtitle?: string;
  /** hidden if undefined, used when using `AvatarTableCell` for user */
  email?: string;
  /** optional tootip text for hovering over username and fullname */
  tooltip?: string;
  /** pass to the onClick/onKeyDown props of the outmost `<div role="button" />` */
  onClick?: () => void;
  /** true if wants to render name with `@`, e.g. `@name`, default is true */
  nameWithAtSign?: boolean;
}

interface AvatarProps {
  image?: string | Icon | null;
}

/**
 * Renders avatar, name with `@` and subtitle (if not `undefined`)
 */
export const AvatarTableCell = ({
  name,
  nameWithAtSign,
  subtitle,
  email,
  tooltip,
  image,
  onClick,
  role,
}: AvatarTableCellProps & AvatarProps) => {
  const title = tooltip ?? (email ? `${subtitle} (${email})` : subtitle);

  const withAtSign = nameWithAtSign ?? true;

  const profileItem: ProfileBlockProps["item"] = useMemo(
    () => ({ avatar: image, title: withAtSign ? `@${name}` : name, subtitle: subtitle || "", avatarTitle: name }),
    [withAtSign, name, image, subtitle],
  );

  const formatTitle = useCallback(
    (props: Parameters<NonNullable<ProfileBlockProps["formatTitle"]>>[0]) => (
      <>
        {props.title}
        {role ? (
          <Lozenge className={styles.role} textTransform="uppercase">
            {role}
          </Lozenge>
        ) : null}
      </>
    ),
    [role],
  );

  return (
    <ProfileBlock
      item={profileItem}
      formatTitle={formatTitle}
      onClick={onClick}
      onKeyDown={onClick ? ({ key }) => key === "Enter" && onClick() : undefined}
      role="button"
      tabIndex={0}
      title={title}
    />
  );
};

interface PendingUserTableCellProps {
  email: string;
}

/**
 * For pending user, only show email
 */
export const PendingUserTableCell = ({ email }: PendingUserTableCellProps) => (
  <div className={styles.lensIDEmail}>{email}</div>
);
