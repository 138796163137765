import { useContext, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { BillingInfo, LensSDKException } from "lens-platform-sdk";
import { misc } from "@k8slens/lds-icons";
import { LoadingIndicator, Notification } from "@k8slens/lds";

import { openUrl } from "src/utils/url";
import { useLensPlatformClient } from "src/hooks/useLensPlatformClient";
import { getUpdateBillingDetailUrl } from "src/services/billing/billingUrl";

import { BusinessContext } from "src/components/Business/Base";
import PageAction from "src/components/PageAction/PageAction";

import styles from "./BillingDetails.module.css";

const { CreditCardIcon, ReceiptIcon } = misc;

const formatValidity = (paymentMethod: NonNullable<BillingInfo["paymentMethod"]>) =>
  paymentMethod.expYear && paymentMethod.expMonth
    ? `${paymentMethod.expMonth}/${paymentMethod.expYear?.toString().slice(2, 4)}`
    : "n/a";
const formatValidityLong = (paymentMethod: NonNullable<BillingInfo["paymentMethod"]>) =>
  paymentMethod.expYear && paymentMethod.expMonth
    ? new Date(
        // We need to set type as number because it was set as Number in the SDK
        paymentMethod.expYear as number,
        (paymentMethod.expMonth as number) - 1,
      ).toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: undefined,
      })
    : "";

export interface BillingProps {
  billingInfo?: BillingInfo;
  billingInfoUrl: string | undefined;
  loading?: boolean;
}

export const getCreditCardNumberDisplayString = (paymentMethod: NonNullable<BillingInfo["paymentMethod"]>) => {
  if (paymentMethod?.lastFour) {
    return `${paymentMethod.cardType} ***${paymentMethod?.lastFour}`;
  }

  return `${paymentMethod.cardType} ${paymentMethod?.firstSix}***`;
};

export const getCreditCardAreaLabelText = (paymentMethod: NonNullable<BillingInfo["paymentMethod"]>) => {
  let creditCardNumber = "";

  if (paymentMethod?.lastFour) {
    creditCardNumber = `ending with ${paymentMethod.lastFour}`;
  } else if (paymentMethod.firstSix) {
    creditCardNumber = `starting with ${paymentMethod.firstSix}`;
  }

  return `${paymentMethod.cardType} ${creditCardNumber}, expiring on ${formatValidityLong(paymentMethod)}.`;
};

export const getAddressString = (address?: BillingInfo["address"]) => {
  if (address) {
    return [address.street1, address.street2, address.city, address.region, address.postalCode, address.country]
      .filter((x) => x)
      .join(", ");
  }

  return "";
};

export const Billing = ({ billingInfo, billingInfoUrl, loading }: BillingProps) => {
  const history = useHistory();
  const lensPlatformClient = useLensPlatformClient();
  const { businessId, business } = useContext(BusinessContext);
  const [error, setError] = useState<LensSDKException>();

  const updatePaymentMethod = () => {
    if (!billingInfoUrl) {
      if (!businessId) {
        history.push("/");

        return;
      }
      lensPlatformClient.business
        .createBillingPageToken(businessId)
        .then(({ hostedLoginToken }) => {
          const updateBillingDetailUrl = getUpdateBillingDetailUrl(hostedLoginToken);

          openUrl(updateBillingDetailUrl, true);
        })
        .catch((error: any) => {
          setError(error);
        });

      return;
    }
    openUrl(billingInfoUrl, true);
  };

  const isPaperInvoicingCustomer = Boolean(business?.external);
  const isCreditCardCustomer = billingInfo?.type === "credit_card" && !isPaperInvoicingCustomer;
  const isInvoicingCustomer = billingInfo?.type === "invoice" && !isPaperInvoicingCustomer;
  const noBillingInfo = !isPaperInvoicingCustomer && billingInfo?.type === null;
  const addressString = getAddressString(billingInfo?.address);
  const loadingData = !(
    noBillingInfo ||
    isCreditCardCustomer ||
    isPaperInvoicingCustomer ||
    isInvoicingCustomer ||
    error
  );

  const updatePaymentMethodDisabledTooltip = useMemo(() => {
    if (isPaperInvoicingCustomer) {
      return "Cannot change payment method for this account. Please contact your sales representative to modify the payment method.";
    } else if (isInvoicingCustomer) {
      return "To update your billing address, please contact our support team (support@k8slens.dev). This option is currently unavailable for invoice customers through the portal.";
    }

    return undefined;
  }, [isInvoicingCustomer, isPaperInvoicingCustomer]);

  return (
    <div>
      {noBillingInfo ? <div className={styles.noPaymentMethod}>No payment method</div> : null}
      {isCreditCardCustomer ? (
        <div className={styles.creditCard}>
          <CreditCardIcon className={styles.paymentType} size="xxl" />
          {billingInfo.paymentMethod ? (
            <div className={styles.creditCardDetails}>
              <span aria-label={getCreditCardAreaLabelText(billingInfo.paymentMethod)}>
                {getCreditCardNumberDisplayString(billingInfo.paymentMethod)}
              </span>
              <span aria-hidden>Valid until: {formatValidity(billingInfo.paymentMethod)}</span>
            </div>
          ) : null}
        </div>
      ) : null}
      {isPaperInvoicingCustomer ? (
        <div className={styles.paperInvocing}>
          <ReceiptIcon className={styles.paymentType} size="xxl" />{" "}
          <div className={styles.paperInvocingDetails}>
            <strong>Paper Invoicing</strong>
            {addressString ? <span className={styles.address}>{addressString}</span> : null}
          </div>
        </div>
      ) : null}
      {isInvoicingCustomer ? (
        <div className={styles.paperInvocing}>
          <ReceiptIcon className={styles.paymentType} size="xxl" />{" "}
          <div className={styles.paperInvocingDetails}>
            <strong>Invoicing</strong>
            {addressString ? (
              <span className={styles.address}>
                {business?.name} {addressString}
              </span>
            ) : (
              "Billing address not available. Please contact support to update your information."
            )}
          </div>
        </div>
      ) : null}
      {loadingData ? (
        <div className={styles.noPaymentMethod}>
          <LoadingIndicator size="sm" />
        </div>
      ) : null}
      {error ? (
        <Notification
          level="error"
          message={error?.message ?? "unknown"}
          type="flash"
          className={styles.errorNotification}
        />
      ) : null}
      {loadingData || noBillingInfo || error ? null : (
        <PageAction
          label="Update payment method"
          buttonProps={{
            title: updatePaymentMethodDisabledTooltip,
            disabled: Boolean(updatePaymentMethodDisabledTooltip),
            onClick: Boolean(updatePaymentMethodDisabledTooltip) ? undefined : updatePaymentMethod,
            discreet: true,
            textTransform: "capitalize",
            loading,
          }}
        />
      )}
    </div>
  );
};
