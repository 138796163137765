import { LensPlatformClient, LensSDKException } from "lens-platform-sdk";
import { useLensPlatformClient } from "./useLensPlatformClient";
import { useCallback, useContext } from "react";
import { ProfileContext } from "src/providers/profile-provider";
import { BusinessContext } from "src/components/Business/Base";
import { getDomainFromEmail } from "src/services/email";
import { useQuery } from "@tanstack/react-query";

export type DomainFeatures = Awaited<ReturnType<LensPlatformClient["domainFeatures"]["get"]>>;

/**
 * Get domain features
 */
export const useGetDomainFeatures = () => {
  const lensPlatformClient = useLensPlatformClient();

  const { profile } = useContext(ProfileContext);
  const { business } = useContext(BusinessContext);

  const fetchDomainFeatures = useCallback(async () => {
    let domain = "";

    if (business?.emailDomain) {
      domain = business?.emailDomain;
    } else {
      if (profile?.email) {
        domain = getDomainFromEmail(profile?.email) || "";
      }
    }

    if (domain !== "") {
      return lensPlatformClient.domainFeatures.get({ domain });
    }

    return Promise.reject(new Error("Domain missing"));
  }, [profile, business, lensPlatformClient.domainFeatures]);

  const {
    isLoading,
    isFetching,
    error,
    data: domainFeatures,
    refetch,
  } = useQuery<DomainFeatures, LensSDKException>({
    queryKey: ["domainFeatures", fetchDomainFeatures],
    queryFn: () => fetchDomainFeatures(),
  });

  return {
    loading: isLoading || isFetching,
    error,
    domainFeatures,
    reload: refetch,
  };
};
