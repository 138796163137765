import { keycloakLensDesktopClientId, legacyKeycloakIframePath } from "src/constants";
import { isAuthorizedRedirectUri } from "./getRedirectAuthToLensDesktopUrl";

export function getAuthRedirectUri() {
  const searchParams = new URLSearchParams(window.location.search);
  const redirectUriParam = searchParams.get("redirect_uri");
  const clientId = searchParams.get("client_id");

  if (redirectUriParam && !isAuthorizedRedirectUri(redirectUriParam)) {
    return "";
  }

  // if the request is from
  // https://github.com/lensapp/lenscloud-lens-extension/blob/bb54fc3fe06a731152c61bc9c79d21f59593fc46/src/renderer/helper/auth/AuthHandler.ts#L86
  const isFromLegacyLensDesktop =
    clientId === keycloakLensDesktopClientId &&
    redirectUriParam?.includes("localhost") &&
    redirectUriParam?.includes("login");

  if (isFromLegacyLensDesktop) {
    // Need to swap the redirect_uri to the current origin because
    // the code to token exchange is done using hard coded `${frontendURL}/logged-in`
    // hence we need to swap to be like the auth code was requested by the iframe is Lens Desktop
    // in addition to that, we need to pass the original redirect_uri to the `${frontendURL}/logged-in`
    // so that logged-in page can redirect to the original redirect_uri
    // https://github.com/lensapp/lenscloud-lens-extension/blob/release/v2/src/renderer/helper/auth/AuthHandler.ts#L117
    const redirectUri = `${window.location.origin}/${legacyKeycloakIframePath}`;

    return redirectUri;
  }

  return "";
}
