import { useBooleanFlagValue } from "@openfeature/react-sdk";
import { LoadingIndicator } from "@k8slens/lds";
import clsx from "clsx";

import { useBusinessIdParam } from "src/hooks/useBusinessIdParam";
import { useGetCurrentBusinessPlan } from "src/hooks/useGetCurrentBusinessPlan";

import PageHeader from "src/components/PageHeader/PageHeader";

import { Analytics, JoinRequest, SeatCount, UserCount } from "./Analytics";
import {
  WhatNext,
  InviteUser,
  ConfigureSSO,
  ConfigureSCIM,
  SetUpDomainMatching,
  PurchaseSubscription,
} from "./WhatNext";
import { Subscription } from "./Subscription";
import { Catalog } from "./Catalog";

import styles from "./page.module.css";

export function BusinessHome() {
  const businessId = useBusinessIdParam();
  const domainMatchingSettingsFlag = useBooleanFlagValue("show-domain-matching-settings", false);
  const lensTeamLicenseFlag = useBooleanFlagValue("lens-team-license", false);

  const { plan, loading, isTeamPlan } = useGetCurrentBusinessPlan(businessId);

  return (
    <>
      <PageHeader
        title="Welcome to your Lens Business ID"
        subtitle={
          <span>
            Your home for managing Lens Subscriptions and settings for organization <strong>{`(${businessId})`}</strong>
            .
          </span>
        }
      />
      {lensTeamLicenseFlag ? <Subscription plan={plan} loading={loading} /> : null}
      <div className={styles.intro}>
        <Analytics
          className={clsx({
            [styles.onlyTextAnalytics]: isTeamPlan,
          })}
        >
          {loading ? null : (
            <>
              {isTeamPlan ? <UserCount /> : <SeatCount />}
              <JoinRequest />
            </>
          )}
        </Analytics>
        <WhatNext>
          {domainMatchingSettingsFlag ? <SetUpDomainMatching /> : null}
          <InviteUser />
          <PurchaseSubscription />
          <ConfigureSSO />
          <ConfigureSCIM />
        </WhatNext>
      </div>
      <Catalog />
      {loading ? <LoadingIndicator size="2xl" /> : null}
    </>
  );
}
