import { sanitizeUrl } from "@braintree/sanitize-url";
import { LoadingIndicator } from "@k8slens/lds";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { legacyKeycloakIframeRedirectLocalStorageKey } from "src/constants";
import { isAuthorizedRedirectUri } from "src/services/getRedirectAuthToLensDesktopUrl";
import { getRedirectToIdpRegistrationUrl } from "src/services/getRedirectToIdpRegistrationUrl";
import { useLocalStorage } from "usehooks-ts";

export const RedirectToIdpRegistration = () => {
  const location = useLocation();
  const [value, setValue] = useLocalStorage(legacyKeycloakIframeRedirectLocalStorageKey, "");

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const redirectUri = searchParams.get("redirect_uri");

    if (redirectUri && isAuthorizedRedirectUri(redirectUri)) {
      setValue(redirectUri);
    }
  }, [location.search, setValue]);

  useEffect(() => {
    // ensure the url is saved before redirecting
    if (value) {
      window.location.replace(sanitizeUrl(getRedirectToIdpRegistrationUrl()));
    }
  }, [value]);

  return <LoadingIndicator size="2xl" />;
};
