import React, { useMemo } from "react";

import { LoadingIndicator, ProfileBlock } from "@k8slens/lds";

import styles from "./LBIDSelect.module.css";

interface Props {
  /** The data of item passed from parent <Select /> */
  item?: {
    id: string;
    label: string;
    subtitle?: string;
  };
}

/**
 * The item component to be used in `<IDSelect />`
 */
const LBIDSelectItem: React.FC<Props> = ({ item }) => {
  const profileItem = useMemo(() => {
    if (!item) {
      return null;
    }

    const title = item.label ?? "";

    // the item is a "Lens Business ID"
    const subtitle = item.subtitle ?? "Lens Business ID";
    const avatarTitle = title.replace(/^@/, ""); // strip @

    return { title, subtitle, avatarTitle };
  }, [item]);

  if (!profileItem) {
    return <LoadingIndicator />;
  }

  return <ProfileBlock item={profileItem} className={styles.profileBlock} />;
};

export default LBIDSelectItem;
