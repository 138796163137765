import { ButtonBar } from "@k8slens/lds";
import { useMemo } from "react";

import { pricingUrl } from "src/constants";

import TrackedButton from "src/components/Button/TrackedButton";
import { openUrl } from "src/utils/url";
import { useBusinessIdParam } from "src/hooks/useBusinessIdParam";
import { type PlanMetadata } from "src/services/plans";
import { useBusiness } from "src/hooks/useBusiness";
import { useBusinessUsers } from "src/hooks/useBusinessUsers";

import SkeletonText from "src/components/SkeletonText/SkeletonText";

import styles from "./Subscription.module.css";

export function Subscription({ plan, loading }: { plan?: PlanMetadata | null; loading?: boolean }) {
  const businessId = useBusinessIdParam();
  const { business } = useBusiness(businessId);
  const { businessUsers } = useBusinessUsers(businessId);

  const isExternal = business?.external === true;

  const actions = useMemo(() => {
    switch (plan?.type) {
      case "team":
        return (
          <ButtonBar className={styles.buttonBar} type="grid" gridSize={2}>
            <TrackedButton
              label={"Upgrade"}
              primary
              size="sm"
              title={
                isExternal
                  ? "To upgrade your organization’s subscription, please contact your sales representative or reach out to support@k8slens.dev."
                  : undefined
              }
              disabled={isExternal}
              onClick={() => {
                if (isExternal) {
                  return;
                }
                openUrl(
                  `${pricingUrl}?businessId=${businessId}&quantity=${businessUsers.length}&company_name=${
                    business?.name ?? ""
                  }`,
                  true,
                );
              }}
            />
            <TrackedButton
              label={"Compare Plans"}
              primary
              discreet
              size="sm"
              onClick={() => {
                openUrl(`${pricingUrl}?businessId=${businessId}#compare-plans`, true);
              }}
            />
          </ButtonBar>
        );
      case "pro":
      case "enterprise":
        return <ButtonBar className={styles.buttonBar} />;
      default:
        return <ButtonBar className={styles.buttonBar} />;
    }
  }, [plan, businessId, business, businessUsers.length, isExternal]);

  return (
    <section className={styles.subscription}>
      <div>
        <h3 className="lds-h4">
          Current Subsription:{" "}
          <SkeletonText loading={Boolean(loading)}>
            <strong className={styles.planName}>{plan?.licenseName}</strong>
          </SkeletonText>
        </h3>
        <div>{actions}</div>
      </div>
    </section>
  );
}
