import { backend } from "src/constants";
import uniqBy from "lodash/uniqBy";

export const sendEmailConfirmation = async (usernameOrEmail: string): Promise<void> => {
  // encodeURIComponent is required to encode email addresses
  const response = await fetch(`${backend}/users/${encodeURIComponent(usernameOrEmail)}/email/confirmation`, {
    method: "PUT",
  });

  if (!response.ok) {
    const json = await response.json();

    throw new Error(json?.message);
  }
};

/**
 * Returns true if the email is valid, taken from https://stackoverflow.com/a/46181/1015046
 */
export const validateEmail = (email: string) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

/**
 * Remove whitespace and split email by comma into an array of email strings
 */
export const getUniqueEmailsArray = (emails: string) =>
  uniqBy(
    emails
      .replace(/ /g, "")
      .split(",")
      .filter((s) => Boolean(s)),
    (s) => s.toLowerCase(),
  );

export const getDomainFromEmail = (email: string) => {
  return email.split("@").pop();
};
