import React, { createContext, useState, useEffect, PropsWithChildren } from "react";
import { AnalyticsBrowser, Analytics, InitOptions, Context } from "@segment/analytics-next";

import { segmentWriteKey as writeKey } from "src/constants";

const blacklistedSearchParameters = ["token", "email"];

export interface AnalyticsContextProps {
  analytics: Analytics | undefined;
  initialized: boolean;
}

let integrations: InitOptions["integrations"] = { All: false, "Segment.io": true };

if (process.env.NODE_ENV === "development") {
  integrations = { All: false };
}

export const AnalyticsContext = createContext<AnalyticsContextProps>({ analytics: undefined, initialized: false });

export const AnalyticsProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const [analytics, setAnalytics] = useState<Analytics | undefined>(undefined);
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (!writeKey) {
      return;
    }
    AnalyticsBrowser.load({ writeKey }, { integrations })
      .then(async (response) => {
        const [_analytics] = response;

        await _analytics.register({
          name: "removeSearchParams",
          type: "before",
          version: "1.0.0",
          isLoaded: () => true,
          load: () => Promise.resolve(),
          page: modifyContext,
        });

        setAnalytics(_analytics);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
      })
      .finally(() => {
        setInitialized(true);
      });
  }, []);

  return <AnalyticsContext.Provider value={{ analytics, initialized }}>{children}</AnalyticsContext.Provider>;
};

function parseAndRemoveBlacklistedParamsFromURL(url: string) {
  const urlObj = new URL(url);

  blacklistedSearchParameters.forEach((param) => urlObj.searchParams.delete(param));

  return urlObj;
}

function modifyContext(ctx: Context): Promise<Context> | Context {
  if (ctx.event.properties?.url) {
    const url = parseAndRemoveBlacklistedParamsFromURL(ctx.event.properties.url);

    ctx.event.properties.url = url.toString();
    ctx.event.properties.search = url.searchParams.toString();
  }

  if (ctx.event.context?.page.url) {
    const url = parseAndRemoveBlacklistedParamsFromURL(ctx.event.context.page.url);

    ctx.event.context.page.url = url.toString();
    ctx.event.context.page.search = url.searchParams.toString();
  }

  return ctx;
}
