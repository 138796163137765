import { useCallback, useMemo, useState } from "react";
import {
  createColumnHelper,
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  CellContext,
} from "@tanstack/react-table";
import { useConfirmDialog } from "@k8slens/lds";
import { base, misc } from "@k8slens/lds-icons";
import { BusinessJoinRequestWithCreatedBy } from "lens-platform-sdk";

import { getUserAvatar } from "src/utils/user";
import { getUserFullname } from "src/services/getUserFullname";
import { useTrackedTableSort } from "src/hooks/useTrackedTableSort";

import Table from "src/components/Table/Table";
import { dateColDef } from "src/components/TableCells/DateCell";
import { AvatarTableCell, PendingUserTableCell } from "src/components/TableCells/AvatarTableCell";
import CheckboxCell, { checkboxColDef, useCheckboxCells } from "src/components/TableCells/CheckboxTableCell";
import ContextMenuCell, { contextMenuColDef } from "src/components/TableCells/ContextMenuCell";
import { ContextMenuItem } from "src/components/ContextMenu/ContextMenu";

import styles from "./Requests.module.css";
import { trimEmail } from "src/services/trimEmail";

const { WarningIcon } = base;
const { ThumbDownIcon, ThumbUpIcon } = misc;

const RejectIcon = () => <ThumbDownIcon color="danger" size="sm" />;
const AcceptIcon = () => <ThumbUpIcon size="sm" />;

const tableColumnHelper = createColumnHelper<BusinessJoinRequestWithCreatedBy>();

const caption = "Join Requests";

export interface RequestProps {
  requests?: Array<BusinessJoinRequestWithCreatedBy>;
  loading?: boolean;
  updateRequests: (d: Array<{ id: string; state: "accepted" | "rejected" | "canceled" }>) => Promise<any>;
  rowsWithErrors?: Array<string>;
}

type SelectedActionsProps = {
  requests: Array<BusinessJoinRequestWithCreatedBy>;
  updateRequests: (d: Array<{ id: string; state: "accepted" | "rejected" | "canceled" }>) => Promise<any>;
};

const SelectedActions = ({ requests, updateRequests: _updateRequests }: SelectedActionsProps) => {
  const { ConfirmDialog, confirm } = useConfirmDialog();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const updateRequests = useCallback(
    async (data: Array<{ id: string; state: "accepted" | "rejected" | "canceled" }>) => {
      setLoading(true);
      setError(false);

      try {
        await _updateRequests(data);
      } catch (err) {
        setError(true);
      }
      setLoading(false);
    },
    [_updateRequests, setLoading],
  );

  const rejectInvites = useCallback(async () => {
    if (await confirm("Are you sure you want to decline all selected requests?")) {
      updateRequests(requests.map((d) => ({ ...d, state: "rejected" })));
    }
  }, [updateRequests, requests, confirm]);
  const approveInvites = useCallback(async () => {
    if (await confirm("Are you sure you want to accept all selected requests?")) {
      updateRequests(requests.map((d) => ({ ...d, state: "accepted" })));
    }
  }, [updateRequests, requests, confirm]);

  return (
    <>
      <ContextMenuCell toggleButtonProps={{ title: "Toggle context menu" }} disabled={loading || !requests.length}>
        {error ? (
          <span title="Something went wrong! Please, try again later.">
            <WarningIcon size="lg" color="caution" />
          </span>
        ) : null}
        <ContextMenuItem
          label="Decline selected"
          onClick={() => rejectInvites()}
          disabled={loading || !requests.length}
          icon={RejectIcon}
          buttonClassName={styles.rejectButton}
        />
        <ContextMenuItem
          label="Approve selected"
          onClick={() => approveInvites()}
          disabled={loading || !requests.length}
          icon={AcceptIcon}
        />
      </ContextMenuCell>
      {ConfirmDialog}
    </>
  );
};

type ActionsProps = {
  request: BusinessJoinRequestWithCreatedBy;
  updateRequests: (d: Array<{ id: string; state: "accepted" | "rejected" | "canceled" }>) => Promise<any>;
};

const Actions = ({ request, updateRequests: _updateRequests }: ActionsProps) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const updateRequest = useCallback(
    async (data: { id: string; state: "accepted" | "rejected" | "canceled" }) => {
      setLoading(true);
      setError(false);

      try {
        await _updateRequests([data]);
      } catch (err) {
        setError(true);
      }
      setLoading(false);
    },
    [_updateRequests, setLoading],
  );

  const rejectInvite = useCallback(
    () => updateRequest({ id: request.id, state: "rejected" }),
    [updateRequest, request],
  );
  const approveInvite = useCallback(
    () => updateRequest({ id: request.id, state: "accepted" }),
    [updateRequest, request],
  );

  return (
    <ContextMenuCell toggleButtonProps={{ title: "Toggle context menu" }}>
      {error ? (
        <span title="Something went wrong! Please, try again later.">
          <WarningIcon size="lg" color="caution" />
        </span>
      ) : null}
      <ContextMenuItem
        label="Decline"
        onClick={() => rejectInvite()}
        disabled={loading}
        icon={RejectIcon}
        buttonClassName={styles.rejectButton}
      />
      <ContextMenuItem label="Approve" onClick={() => approveInvite()} disabled={loading} icon={AcceptIcon} />
    </ContextMenuCell>
  );
};

const Requests = ({ requests, updateRequests, loading, rowsWithErrors }: RequestProps) => {
  const { checkedArr, isChecked, onCheckOne, allChecked, onCheckAll, unCheckAll, checkAllDisabled } = useCheckboxCells(
    requests || [],
  );

  const checkAllProps = useMemo(
    () => ({
      value: allChecked,
      onChange: onCheckAll,
      disabled: checkAllDisabled,
    }),
    [allChecked, onCheckAll, checkAllDisabled],
  );

  const onUpdateRequests = useCallback(
    (data: Array<{ id: string; state: "accepted" | "rejected" | "canceled" }>) => {
      return updateRequests(data).finally(() => {
        unCheckAll();
      });
    },
    [updateRequests, unCheckAll],
  );
  const tableColumns = [
    tableColumnHelper.accessor("id", {
      header: () => <CheckboxCell checkboxProps={checkAllProps} isHeader className={styles.checkbox} />,
      cell: ({ row }) => (
        <CheckboxCell
          checkboxProps={{
            value: isChecked(row.original.id),
            onChange: () => onCheckOne(row.original.id),
          }}
          className={styles.checkbox}
        />
      ),
      ...checkboxColDef,
    }),
    tableColumnHelper.accessor("createdBy", {
      header: () => <span>Lens ID</span>,
      cell: ({ getValue }) => {
        const user = getValue();

        const { username, email } = user;
        const userAvatar = getUserAvatar(user);

        // User is invited to the business, but not yet have a Lens ID account
        const userNotHaveAccount = email && !username;

        if (userNotHaveAccount) {
          return <PendingUserTableCell email={email} />;
        }

        const fullname = getUserFullname(user);

        return <AvatarTableCell image={userAvatar} name={username ?? ""} subtitle={fullname} />;
      },
      meta: { primary: true },
    }),
    tableColumnHelper.accessor("createdBy", {
      header: () => <span>Email</span>,
      cell: ({ getValue }) => {
        const { email } = getValue();

        return (
          <address title={email}>
            <a href={`mailto:${email}`} className={styles.emailLink}>
              {trimEmail(email)}
            </a>
          </address>
        );
      },
      sortingFn: (a, b) => {
        const sort = a.original.createdBy.email?.localeCompare?.(b.original.createdBy.email ?? "");

        if (sort) {
          return sort;
        }

        return 0;
      },
    }),
    tableColumnHelper.accessor("createdAt", {
      ...dateColDef,
      header: () => <span>Requested At</span>,
    }),
    tableColumnHelper.accessor("id", {
      ...contextMenuColDef,
      header: useCallback(
        () => (
          <SelectedActions
            requests={(requests || []).filter(({ id }) => checkedArr.includes(id))}
            updateRequests={onUpdateRequests}
          />
        ),
        [requests, checkedArr, onUpdateRequests],
      ),
      cell: useCallback(
        ({ row }: CellContext<BusinessJoinRequestWithCreatedBy, "string">) => (
          <Actions request={row.original} updateRequests={onUpdateRequests} />
        ),
        [onUpdateRequests],
      ),
    }),
  ];
  const { sorting, onSortingChange } = useTrackedTableSort({
    table: caption,
    initialSort: [{ id: "createdAt", desc: true }],
  });

  const pendingUserTable = useReactTable({
    data: requests || [],
    columns: tableColumns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting,
    },
    onSortingChange,
  });
  const rowModel = pendingUserTable.getRowModel();
  const rows = useMemo(() => rowModel.rows, [rowModel]);

  return (
    <>
      <Table<BusinessJoinRequestWithCreatedBy>
        caption={caption}
        loading={loading}
        rows={rows}
        columns={pendingUserTable.getAllColumns()}
        headerGroups={pendingUserTable.getHeaderGroups()}
        noDataText="No pending requests on your Lens Business ID"
        rowProps={{
          rowHasError: (row) => Boolean(rowsWithErrors?.includes(row.original.id)),
        }}
        borderless={false}
      />
      <p className={styles.description}>
        Users listed here have requested to join your organization. You can accept or decline their requests. While
        pending, they will remain on the Lens Personal license and will not have access to your organization's features.
      </p>
    </>
  );
};

export default Requests;
