import { useContext } from "react";
import clsx from "clsx";
import { Switch, Notification } from "@k8slens/lds";

import { useBusiness } from "src/hooks/useBusiness";
import { useGetCurrentBusinessPlan } from "src/hooks/useGetCurrentBusinessPlan";
import { isLensTeamPlan } from "src/services/plans";

import { BusinessContext } from "./Base";

import styles from "./AutoJoinSwitch.module.css";

export const AutoJoinSwitch = () => {
  const { businessId, business } = useContext(BusinessContext);
  const { updateBusiness, updatingBusiness, errorUpdatingBusiness } = useBusiness(businessId);
  const { plan, loading: loadingPlan } = useGetCurrentBusinessPlan(businessId);
  const checked = Boolean(business?.automaticSeatAssignment);

  const isTeamPlan = isLensTeamPlan(plan?.type);

  return (
    <section className={styles.autoJoinSwitchWrapper}>
      <section className="self-center">
        <h3 className={clsx("lds-form-label lds-form-field--label", styles.switchLabel)}>Automatic seat assignment</h3>
        <p className={clsx("lds-form-switch-group--description", styles.switchDescription)}>
          Users in your organization will be automatically assigned a seat.
        </p>
        {errorUpdatingBusiness ? (
          <Notification level="error" message={errorUpdatingBusiness.message} type="closable" className="my-5" />
        ) : null}
      </section>
      <Switch
        className={styles.switch}
        disabled={updatingBusiness || loadingPlan || isTeamPlan}
        onChange={(newchecked) => {
          if (!business) {
            return;
          }
          updateBusiness({
            automaticSeatAssignment: newchecked,
          });
        }}
        checked={checked}
      />
    </section>
  );
};
