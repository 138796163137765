import { useCallback, useContext, useEffect, useState } from "react";
import { useBooleanFlagValue } from "@openfeature/react-sdk";
import { LoadingIndicator, Notification } from "@k8slens/lds";
import { FormSwitchGroup } from "@k8slens/lds-form";
import { type SwitchValue } from "@k8slens/lds-form/lib/es/FormSwitchGroup/FormSwitchGroup";

import { useBusiness } from "src/hooks/useBusiness";
import { useAnalytics } from "src/hooks/useAnalytics";
import { ProfileContext } from "src/providers/profile-provider";

import { BusinessContext } from "src/components/Business/Base";
import PageHeader from "src/components/PageHeader/PageHeader";

import styles from "./page.module.css";

const DOMAIN_MATCHING_ENABLED = "domain-matching-enabled";
const AUTO_ACCEPT_JOIN_REQUESTS = "auto-accept-join-requests-enabled";

const updateAction = "Update Domain Matching Settings";

const BusinessDomainMatching = () => {
  const { track } = useAnalytics();
  const { profile } = useContext(ProfileContext);
  const { businessId } = useContext(BusinessContext);
  const {
    updateBusiness,
    errorUpdatingBusiness,
    updatingBusiness,
    business: initialBusiness,
    loadingBusiness,
  } = useBusiness(businessId);
  const domainMatchingSettingsFlag = useBooleanFlagValue("show-domain-matching-settings", false);

  const [switches, setSwitches] = useState<Array<SwitchValue>>([]);

  const handleSwitchChange = useCallback(
    async (nextSwitches: Array<SwitchValue>, keys: Array<string>) => {
      const changed = nextSwitches.filter(({ id }) => keys.includes(id));

      if (changed.find((d) => d.id === DOMAIN_MATCHING_ENABLED || d.id === AUTO_ACCEPT_JOIN_REQUESTS)) {
        track(updateAction);

        if (changed.find((d) => d.id === DOMAIN_MATCHING_ENABLED)) {
          updateBusiness({
            emailDomainMatchingEnabled: changed.find((s) => s.id === DOMAIN_MATCHING_ENABLED)?.checked,
          });
        }

        if (changed.find((d) => d.id === AUTO_ACCEPT_JOIN_REQUESTS)) {
          updateBusiness({ autoAcceptJoinRequests: changed.find((s) => s.id === AUTO_ACCEPT_JOIN_REQUESTS)?.checked });
        }
      }
    },
    [track, updateBusiness],
  );

  const userCompanyEmailDomain = profile?.companyEmailDomain;
  const disabled = !(domainMatchingSettingsFlag && userCompanyEmailDomain);

  useEffect(() => {
    if (!initialBusiness) {
      return;
    }
    let emailDomain = userCompanyEmailDomain || "yours";

    if (initialBusiness.emailDomainMatchingEnabled) {
      emailDomain = initialBusiness.emailDomain || emailDomain;
    }
    setSwitches([
      {
        id: DOMAIN_MATCHING_ENABLED,
        label: "Enable Domain Matching",
        description: `Lens users can discover your organization and request to join if their verified email domain matches ${emailDomain}.`,
        checked: initialBusiness.emailDomainMatchingEnabled,
      },
      {
        id: AUTO_ACCEPT_JOIN_REQUESTS,
        label: "Automatically accept join requests",
        description:
          "Users requesting to join your organization through domain matching are automatically accepted and no manual approval is required.",
        checked: initialBusiness.autoAcceptJoinRequests,
      },
    ]);
  }, [initialBusiness, userCompanyEmailDomain]);

  return (
    <>
      {errorUpdatingBusiness ? (
        <Notification flashDuration={1000} level="error" message={errorUpdatingBusiness.message} type="flash" />
      ) : null}
      <PageHeader
        id="domain-matching-settings"
        title="Domain Matching"
        subtitle={
          <>
            Streamline user onboarding by leveraging domain matching to automatically connect users with your
            organization in Lens. These settings allow you to enable domain based access, automate user joins, and
            customize messaging for a seamless onboarding experience.
          </>
        }
      />
      <section>
        {initialBusiness && (
          <FormSwitchGroup
            label=""
            values={switches || []}
            onChange={handleSwitchChange}
            disabled={disabled || loadingBusiness || updatingBusiness}
          />
        )}
      </section>
      {(updatingBusiness || loadingBusiness) && <LoadingIndicator size="xxl" className={styles.loadingIndicator} />}
    </>
  );
};

export default BusinessDomainMatching;
