import { useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { Avatar, Button } from "@k8slens/lds";

import { useInvitationKey, invitationKeyParameter } from "src/hooks/useInvitationKey";
import { useInvitation } from "src/hooks/useInvitation";
import { useAnalytics } from "src/hooks/useAnalytics";
import { useAuth } from "src/hooks/useAuth";
import { acceptEmailInvitation } from "src/services/invitation";
import { delay } from "src/utils/delay";
import { removeQueryString } from "src/services/history";

import CurrentUser from "src/components/CurrentUser/CurrentUser";
import PublicLayout from "src/components/PublicLayout/PublicLayout";

import styles from "./page.module.css";

const panelProps = {
  contentProps: {
    className: styles.content,
  },
};

type Props = {
  changingUsername?: boolean;
};

const acceptLabel = "Accept";
const cancelLabel = "Cancel";

export const SpaceInvitation = ({ changingUsername }: Props) => {
  const { track, trackError, trackButtonClicked } = useAnalytics();
  const history = useHistory();
  const auth = useAuth();
  const invitationKey = useInvitationKey();
  const { invitation, error: invitationError } = useInvitation();
  const [isAccepting, setIsAccepting] = useState(false);
  const acceptDisabled = isAccepting || !invitation;
  const [acceptError, setAcceptError] = useState("");
  const errorText = acceptError || invitationError?.message || "";
  const [acceptSuccess, setAcceptSuccess] = useState("");
  const acceptInvitation = async () => {
    if (!invitation) {
      throw new Error("Invitation not found");
    }

    if (!invitationKey) {
      throw new Error("Invitation key not found");
    }

    trackButtonClicked(acceptLabel);

    try {
      setIsAccepting(true);

      await acceptEmailInvitation(invitation?.id as string, invitationKey, auth?.token || "");

      track("Invitation accepted");
      setAcceptSuccess("Invitation accepted.");

      // Clear previous error if set
      setAcceptError("");

      // user would have enough time to see "Invitation accepted."
      if (process.env.NODE_ENV !== "test") {
        await delay(4000);
      }

      // Remove query string to hide the invitation UI
      removeQueryString(history, invitationKeyParameter);
    } catch (error) {
      trackError("Invitation accepting failed");
      setAcceptError(error instanceof Error ? error.message : "");
      setAcceptSuccess("");
    } finally {
      setIsAccepting(false);
    }
  };
  const cancel = () => {
    trackButtonClicked(cancelLabel);
    removeQueryString(history, invitationKeyParameter);
  };

  return (
    <PublicLayout
      title="Accept Invitation"
      size="lg"
      header={
        <div className={styles.invitationInfo}>
          <span>
            You have been invited to <strong>{invitation?.spaceName}</strong> space by{" "}
            <strong>{invitation?.createdBy?.username}</strong>.
          </span>
        </div>
      }
      notification={errorText || acceptSuccess}
      notificationProps={useMemo(
        () => ({
          level: errorText ? "error" : "success",
        }),
        [errorText],
      )}
      panelProps={panelProps}
      footer={
        <>
          <Button label="Cancel" discreet disabled={acceptDisabled} onClick={cancel} />
          <Button loading={acceptDisabled} onClick={acceptInvitation} label={acceptLabel} type="primary" />
        </>
      }
      pageFooter={<CurrentUser changingUsername={changingUsername} />}
    >
      <div className={styles.whatIsSpace}>
        <h3 className="lds-h4">What is a space?</h3>
        <ul className={styles.whatIsSpaceUl}>
          <li className={styles.whatIsSpaceLi}>Easy and secure access to Kubernetes clusters</li>
          <li className={styles.whatIsSpaceLi}>Collaborate with team on cloud native development</li>
        </ul>
      </div>
      <div className={styles.spaceInfoWrapper}>
        <Avatar name={invitation?.spaceName ?? ""} className={styles.invitationAvatar} />
        <span className={styles.spaceInfoName}>{invitation?.spaceName ?? ""}</span>
      </div>
    </PublicLayout>
  );
};
